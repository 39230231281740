module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#FBFAFF","theme_color":"#040014","display":"minimal-ui","icon":"src/assets/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2359d8fbf28f4577497653f8a0be4f93"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T9HZHRG","includeInDevelopment":true,"routeChangeEventName":"route-change","defaultDataLayer":null},
    },{
      plugin: require('../node_modules/gatsby-styled-components-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"light":{"fontFamily":{"primary":"Telegraf, Arial, Helvetica, sans-serif","secondary":"MonumentExtended, Arial, Helvetica, sans-serif"},"screen":{"mobileLarge":"400px","tablet":"700px","desktop":"900px","desktopLarge":"1200px","max":"1440px"},"padding":{"mobile":"16px","tablet":"60px","desktop":"80px"},"color":{"main":"#040014","mainBackground":"#FBFAFF","hover":"#5833FF","header":"rgba(251, 250, 255, 0.8)","black":"#040014","blue":"#B4F2F9","green":"#18D88B","orange":"#FE802C","pink":"#FFAFCB","white":"#FBFAFF","yellow":"#E9DF7E"}},"dark":{"fontFamily":{"primary":"Telegraf, Arial, Helvetica, sans-serif","secondary":"MonumentExtended, Arial, Helvetica, sans-serif"},"screen":{"mobileLarge":"400px","tablet":"700px","desktop":"900px","desktopLarge":"1200px","max":"1440px"},"padding":{"mobile":"16px","tablet":"60px","desktop":"80px"},"color":{"main":"#FBFAFF","mainBackground":"#040014","hover":"#5833FF","header":"rgba(4, 0, 20, 0.8)","black":"#040014","blue":"#B4F2F9","green":"#18D88B","orange":"#FE802C","pink":"#FFAFCB","white":"#FBFAFF","yellow":"#E9DF7E"}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
